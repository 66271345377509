import React from "react"
import Helmet from "react-helmet"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Staff from '../components/Staff'
import styles from '../styles/staff.module.scss'

const TherapistsPage = ({
  data: {
    site,
    allMarkdownRemark: { edges }
  },
}) => {

  const Therapists = edges
    .map(edge => <Staff data={edge.node} body={edge.html} />)

  return (
    <Layout>
      <Helmet>
        <title>Our Therapists — {site.siteMetadata.title}</title>
        <meta name="description" content={"Our Therapists " + site.siteMetadata.description} />
      </Helmet>
      <div className="-therapists">
        <h1 className="post-title">Our Therapists</h1>
        <div className={styles.staffList}>

          {Therapists}

        </div>
      </div>
    </Layout>
  )
}
export default TherapistsPage
export const pageQuery = graphql`
  query TherapistsPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___order]}, limit: 1000, filter: {frontmatter: {template: {eq: "StaffProfile"}}}) {
      edges {
        node {
          id
          frontmatter {
            path
            name
            bio
            position
            image
          }
          html
        }
      }
    }
  }
`