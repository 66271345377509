import React from "react"
import styles from './Staff.module.scss'

export default function Staff({ data }) {
  return (
  <div className={styles.staff}>
    <div className={styles.header}>
      { data.frontmatter.image &&
        <div className={styles.imageContainer}>
          <img
            className={styles.image}
            src={ data.frontmatter.image }
            alt={ data.frontmatter.name } />
        </div>
      }
      <div className={styles.title}>
        <h2 className={styles.name}>{ data.frontmatter.name }</h2>
        <h3 className={styles.position}>{ data.frontmatter.position }</h3>
      </div>
    </div>
    <div className={styles.body}>{ data.frontmatter.bio }</div>
    <div
      className={styles.body}
      dangerouslySetInnerHTML={{ __html: data.html }}
    />
  </div>
)}
